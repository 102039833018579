<template>
  <Fragment>
    <tr class="no-hover">
      <td rowspan="2">
        <small v-if="respostaProduto.cotacaoProduto.produto">
          (
          <px-span-tooltip
            top
            :text="respostaProduto.cotacaoProduto.produto.codigo"
            tooltip="Código do item"/>
          )</small>

        {{ respostaProduto.cotacaoProduto.produto.descricao }}
        <px-cotacao-produto-detalhes-popover
          :cotacao-produto="respostaProduto.cotacaoProduto"/>
      </td>
      <td rowspan="2">
        {{
          respostaProduto.cotacaoProduto.quantidade
            | number(getCurrencyFormat(
            respostaProduto.cotacaoProduto.produto.casasDecimais))
        }}
        {{ respostaProduto.cotacaoProduto.unidadeMedida.descricao }}
      </td>
      <td colspan="4" style="border-bottom: none;" v-if="!isRespondida">
        <px-situacao-resposta-produto-chip
          close
          :situacao="respostaProduto.situacao"
          @close="onCloseSituacao(respostaProduto)"/>
      </td>
      <template v-if="isRespondivel">
        <td class="px-1" style="height: 60px; border-bottom: none;">
          <px-input-money
            dense
            :label="labelPreco"
            ref="precoUnitario"
            class="required"
            :error="$v.respostaProduto.precoUnitario.$error"
            :precision="respostaProduto.cotacaoProduto.produto.casasDecimais"
            @blur="$v.respostaProduto.precoUnitario.$touch()"
            v-model="respostaProduto.precoUnitario"/>
        </td>
        <td class="px-1" style="border-bottom: none;">
          <px-input-percentage
            dense
            label="ICMS"
            class="required"
            ref="icms"
            :error="$v.respostaProduto.impostos.icms.$error"
            @blur="$v.respostaProduto.impostos.icms.$touch()"
            v-model="respostaProduto.impostos.icms">
            <template v-slot:append>
              <px-resposta-cadastro-produto-replicar-preco
                @click="onClickReplicarImposto(respostaProduto, 'icms')"
                tooltip-text="Replicar imposto para os outros produtos"/>
            </template>
          </px-input-percentage>
        </td>
        <td class="px-1" style="border-bottom: none;">
          <px-input-percentage
            dense
            label="IPI"
            class="required"
            ref="ipi"
            :error="$v.respostaProduto.impostos.ipi.$error"
            @blur="$v.respostaProduto.impostos.ipi.$touch()"
            v-model="respostaProduto.impostos.ipi">
            <template v-slot:append>
              <px-resposta-cadastro-produto-replicar-preco
                @click="onClickReplicarImposto(respostaProduto, 'ipi')"
                tooltip-text="Replicar imposto para os outros produtos"/>
            </template>
          </px-input-percentage>
        </td>
        <td rowspan="2" class="text-center" style="width: 150px">
          <px-anexo badge-color="primary"
                    v-model="respostaProduto.arquivos"
                    :max="3"
                    top
                    :bottom="false"
                    upload-base-path="external/files"
                    @loading="onFileLoading">
          </px-anexo>
          <px-recusar-produto @change="onChangeSituacao" class="d-inline-block">
            <template v-slot:activator="{ on }">
              <v-tooltip top>
                <template v-slot:activator="{ on: onTooltip }">
                  <v-btn color="error" icon v-on="{...on, ...onTooltip}">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Recusar produto</span>
              </v-tooltip>
            </template>
          </px-recusar-produto>
        </td>
      </template>
    </tr>
    <tr class="no-hover">
      <td v-if="!isRespondida" class="default-table-border" colspan="4" ></td>
      <td v-if="isRespondivel" class="px-1 default-table-border" colspan="3">
        <v-textarea
          dense
          outlined
          auto-grow
          hide-details
          class="mb-3"
          label="Especificação"
          rows="1"
          v-model="respostaProduto.especificacaoProduto"
        />
      </td>
    </tr>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
import validationMixin from '@/mixins/validation-mixin';
import { SituacaoRespostaCotacaoProduto } from 'px-business-components';
import { required } from 'vuelidate/lib/validators';
import { CasaDecimalUtils } from 'px-components';
import CotacaoProdutoDetalhesPopover from './CotacaoProdutoDetalhesPopover.vue';
import RespostaCadastroProdutoReplicarPreco from './RespostaCadastroProdutoReplicarPreco.vue';

export default {
  props: {
    respostaProduto: {
      type: Object,
      required: true,
    },
  },
  mixins: [validationMixin],
  computed: {
    labelPreco() {
      return `Preço por ${this.respostaProduto.cotacaoProduto.unidadeMedida.descricao}`;
    },
    getCurrencyFormat() {
      return item => CasaDecimalUtils.getCurrencyFormat(item);
    },
    isRespondivel() {
      return this.isRespondida;
    },
    isRespondida() {
      return this.respostaProduto.situacao
        === SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key;
    },
  },
  methods: {
    onChangeSituacao(situacao) {
      this.respostaProduto.situacao = situacao.key;
    },
    onCloseSituacao() {
      this.respostaProduto.situacao = SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key;
    },
    onClickReplicarPreco() {
      this.$emit('replicar-preco', this.respostaProduto);
    },
    onClickReplicarImposto(respostaProduto, tipoImposto) {
      this.$emit('replicar-imposto', respostaProduto, tipoImposto);
    },
    onFileLoading(loading) {
      this.respostaProduto.$$hasFileLoading = loading;
    },
  },
  validations() {
    if (!this.isRespondivel) {
      return {};
    }
    return {
      respostaProduto: {
        precoUnitario: {
          required,
          minValue: value => value > 0,
        },
        impostos: {
          icms: {
            required,
            minValue: value => value >= 0,
          },
          ipi: {
            required,
            minValue: value => value >= 0,
          },
        },
      },
    };
  },
  components: {
    pxCotacaoProdutoDetalhesPopover: CotacaoProdutoDetalhesPopover,
    pxRespostaCadastroProdutoReplicarPreco: RespostaCadastroProdutoReplicarPreco,
    Fragment,
  },
};
</script>
